<template>
  <main class="mt-4">
    <trac-loading class="" v-if="loading" />
    <h2 class="text-sm">Sales</h2>
    <div class="flex items-center justify-between">
      <h1 class="font-extrabold mt-3">Sales History</h1>
    </div>

    <trac-modal v-if="viewStoreModal" @close="viewStoreModal = false">
      <div class="mt-5 grid grid-cols-3 gap-5">
        <div
          class="text-sm text-primaryBlue capitalize"
          v-for="(stores, i) in selectedStoresDetails"
          :key="i"
        >
          {{ stores.name }}
        </div>
      </div>
    </trac-modal>

    <div
      class="bg-white shadow-2xl rounded-xl p-6 mt-4 mb-10 inline-block w-128 mr-5"
    >
      <div class="mb-6 flex flex-wrap">
        <div class="flex gap-3 flex-wrap">
          <trac-capsule
            v-for="(store, i) in selectedStoresDetails"
            :key="i"
            :name="store.name"
            @close="removeStoreFilter(store)"
          />
        </div>
        <div
          v-if="selectedStoresDetails.length > 10"
          class="rounded py-1 bg-backgroundArea mx-1 px-2"
        >
          <span class="text-xs text-gray-500"
            >+{{ selectedStoresDetails.slice(10).length }} more</span
          >
        </div>
      </div>
      <p class="text-xs text-gray-600 my-1">
        {{
          time === "lastMonth"
            ? "Last Month"
            : time === "currentMonth"
            ? "This Month"
            : time === "allTime"
            ? "All Time"
            : "Today\’s"
        }}
        Payment
      </p>
      <div class="flex justify-between items-center">
        <p class="text-xl font-bold">{{ sumOfCurrentTime | formatPrice }}</p>
        <select
          v-model="time"
          name=""
          id=""
          class="text-xs border rounded p-2 text-gray-600 cursor-pointer"
        >
          <option value="allTime">All Time</option>
          <option value="today">Today</option>
          <option value="currentMonth">This Month</option>
          <option value="lastMonth">Last Month</option>
        </select>
      </div>
    </div>

    <div class="flex justify-between items-center mr-5">
      <h4 class="font-medium text-xl">Transactions</h4>
      <!-- <trac-button @button-clicked="$router.push({ name: 'Reports' })">
        Download Report
      </trac-button> -->
    </div>

    <div class="shadow-2xl rounded-xl md:mt-6 py-4 px-4 bg-white mr-5 mt-6">
      <div class="mt-4 pr-4 md:pr-0">
        <main id="table">
          <div class="mr-1">
            <div
              class="grid md:flex md:justify-between w-full items-center px-5"
            >
              <div class="flex mt-3 sm:mt-0">
                <div class="flex items-center gap-0 md:ml-5">
                  <div class="text-xs hidden md:block"></div>
                  <!-- Filter by Store -->
                  <div class="md:ml-4 w-full mr-3">
                    <div
                      @click="paymentStore = !paymentStore"
                      v-click-outside="paymentDrop"
                      class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-3 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2 md:mb-0 md:mt-0"
                    >
                      <div
                        class="h-full w-full flex items-center justify-between whitespace-no-wrap"
                      >
                        Select Store
                        <svg
                          class="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-if="paymentStore"
                      @click.stop
                      class="max-w-lg overflow-y-auto border items-center mt-2 ring w-full gap-5 py-2 absolute grid grid-cols-3 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    >
                      <div
                        class="flex items-center cursor-pointer capitalize text-xs"
                      >
                        <input
                          v-model="paymentAll"
                          type="checkbox"
                          class="ml-4"
                          @input="filterFlag = 'store'"
                        />
                        <div class="ml-3">All</div>
                      </div>
                      <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                        v-for="(store, index) in getAllCsv"
                        :key="index"
                      >
                        <div
                          class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <div
                            class="flex items-center cursor-pointer capitalize text-xs"
                          >
                            <input
                              :value="store._id"
                              v-model="selectedStores"
                              type="checkbox"
                              class="mr-3"
                              @input="filterFlag = 'store'"
                            />
                            {{ store.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="md:ml-4 w-full relative">
                    <button
                      @click="active = !active"
                      v-click-outside="dismissdateFilterDropdown"
                      id="options-menu"
                      aria-haspopup="true"
                      aria-expanded="true"
                      type="button"
                      class="whitespace-no-wrap border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-3 rounded inline-flex justify-between capitalize items-center w-full mt-2 mb-4 md:mt-0 md:mb-0"
                    >
                      Filter by date
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </button>
                    <div
                      v-show="active"
                      @click="active = false"
                      class="absolute white-gradient px-4 py-6 bg-white mt-1 rounded-md w-screen md:w-128"
                      style="left: -100%"
                    >
                      <div class="flex w-full justify-between">
                        <div class="w-full ml-8">
                          <p class="text-xs font-medium">By Date</p>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              v-model="time"
                              value="allTime"
                              name=""
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">All Time</p>
                          </div>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              v-model="time"
                              value="today"
                              name=""
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">Today</p>
                          </div>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              value="currentMonth"
                              v-model="time"
                              name=""
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">This Month</p>
                          </div>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              name=""
                              value="lastMonth"
                              v-model="time"
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">Last Month</p>
                          </div>
                          <div
                            @click.stop="toggle = !toggle"
                            class="mt-2 flex relative items-center"
                          >
                            <svg
                              class="w-3 h-3"
                              :class="{
                                'fill-current text-blue-500': time == 'custom',
                              }"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.975586"
                                y="2.43408"
                                width="12.7281"
                                height="12.2995"
                                rx="1.65333"
                                stroke="#253B95"
                                stroke-width="1.23999"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.168 1.06738V3.8006"
                                stroke="#253B95"
                                stroke-width="1.23999"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M4.51079 1.06738V3.8006"
                                stroke="#253B95"
                                stroke-width="1.23999"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M0.975586 6.53404H13.7037"
                                stroke="#253B95"
                                stroke-width="1.23999"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <p
                              @click="open = !open"
                              class="text-xs ml-3 leading-7 text-primaryBlue cursor-pointer"
                            >
                              Select Date
                            </p>
                            <div class="relative">
                              <date-picker
                                ref="datepicker"
                                v-model="custom"
                                :inline="true"
                                v-if="open"
                                @change="handleChange"
                                format="YYYY-MM-DD"
                                class="absolute shadow-md curr"
                                range
                              >
                              </date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="border-l pl-8 w-full">
                          <p class="text-xs font-medium">By Status</p>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              name=""
                              v-model="time"
                              value="pending"
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">Pending</p>
                          </div>
                          <div class="mt-2 flex items-center">
                            <input
                              type="radio"
                              v-model="time"
                              value="paid"
                              name=""
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">Paid</p>
                          </div>
                          <div class="mt-2 flex items-center">
                            <input
                              v-model="time"
                              value="failed"
                              type="radio"
                              name=""
                              id=""
                            />
                            <p class="text-xs ml-3 leading-7">Failed</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="items-end justify-end w-full hidden md:flex">
                    <div class="md:ml-4 w-full">
                      <div
                        @click="paymentDropdownData = !paymentDropdownData"
                        v-click-outside="dismissPaymentDropdown"
                        class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-3 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2 md:mb-0 md:mt-0"
                      >
                        <div
                          class="h-full w-full flex items-center justify-between whitespace-no-wrap"
                        >
                          Filter by Payment
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        class="bg-white absolute border right-0 rounded-lg mt-2"
                        v-if="paymentDropdownData"
                      >
                        <div
                          @click.stop
                          class="grid grid-cols-3 max-w-lg overflow-y-auto items-center mt-2 ring w-full gap-5 py-2 z-50 bg-white ring-1 ring-black ring-opacity-5"
                        >
                          <div
                            class="flex items-center cursor-pointer capitalize text-xs"
                          >
                            <input
                              v-model="allPaymentMethods"
                              id="all-employees"
                              type="checkbox"
                              class="ml-4"
                              @input="filterFlag = 'payment'"
                            />
                            <label
                              class="ml-3 cursor-pointer"
                              for="all-employees"
                              >All</label
                            >
                          </div>
                          <div
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                            v-for="(paymentMethod, index) in paymentMethods"
                            :key="index"
                          >
                            <div
                              class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              <div
                                class="flex items-center cursor-pointer capitalize text-xs"
                              >
                                <input
                                  :value="paymentMethod._id"
                                  v-model="selectedPaymentMethods"
                                  type="checkbox"
                                  :id="'payment-method-' + index"
                                  class="mr-3"
                                  @input="filterFlag = 'payment'"
                                />
                                <label
                                  class="cursor-pointer"
                                  :for="'payment-method-' + index"
                                  >{{ paymentMethod.name }}</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="items-end justify-end w-full hidden md:flex">
                    <div class="md:ml-4 w-full">
                      <div
                        @click="employeeDropdownData = !employeeDropdownData"
                        v-click-outside="employeeDropdown"
                        class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-3 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2 md:mb-0 md:mt-0"
                      >
                        <div
                          class="h-full w-full flex items-center justify-between whitespace-no-wrap"
                        >
                          Filter by Employee
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        class="bg-white absolute border right-0 rounded-lg mt-2"
                        v-if="employeeDropdownData"
                      >
                        <div
                          @click.stop
                          class="flex w-full p-4 items-center gap-3 bg-gray-100"
                        >
                          <p class="text-xs whitespace-no-wrap font-semibold">
                            Filter by Employee
                          </p>
                          <input
                            v-model="searchValue"
                            type="search"
                            name=""
                            id=""
                            class="border w-full outline-none py-1 px-2 rounded-sm text-xs"
                          />
                        </div>
                        <div
                          @click.stop
                          class="grid grid-cols-3 max-w-lg overflow-y-auto items-center mt-2 ring w-full gap-5 py-2 z-50 bg-white ring-1 ring-black ring-opacity-5"
                        >
                          <div
                            class="flex items-center cursor-pointer capitalize text-xs"
                            v-show="
                              'all'.includes(searchedUserName.toLowerCase())
                            "
                          >
                            <input
                              v-model="allEmployees"
                              :checked="
                                selectedEmployees.length === users.length
                              "
                              @click="updateSelectedEmployee"
                              id="all-employees"
                              type="checkbox"
                              class="ml-4"
                              @input="filterFlag = 'employees'"
                            />
                            <label
                              class="ml-3 cursor-pointer"
                              for="all-employees"
                              >All</label
                            >
                          </div>
                          <div
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                            v-for="(user, index) in filteredUsers"
                            :key="index"
                          >
                            <div
                              class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              <div
                                class="flex items-center cursor-pointer capitalize text-xs"
                              >
                                <input
                                  :value="user._id"
                                  v-model="selectedEmployees"
                                  type="checkbox"
                                  :id="'Employee-' + index"
                                  class="mr-3"
                                  @input="filterFlag = 'employees'"
                                />
                                <label
                                  class="cursor-pointer"
                                  :for="'Employee-' + index"
                                  >{{ user.first_name }}
                                  {{ user.last_name }}</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            filteredUsers.length === 0 &&
                            searchedUserName.length > 0
                          "
                          class="h-48 flex justify-center items-center"
                        >
                          <p class="text-xs text-gray-500">No match found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="relative max-w-sm rounded-md w-full flex items-center"
              >
                <input
                  type="text"
                  placeholder="Search customer name or receipt number"
                  class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
                  v-model="search"
                />
                <svg
                  class="w-4 h-4 absolute right-0 mr-6"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="6.56705"
                    cy="6.61686"
                    r="5.39909"
                    stroke="#253B95"
                    stroke-width="1.06786"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3156 13.3659L10.3799 10.4302"
                    stroke="#253B95"
                    stroke-width="1.06786"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </main>
      </div>

      <trac-dashboard-table
        class="mt-4 overflow-hidden rounded-lg"
        :tableHeaders="tableHeaders"
        :data="sortbyOtherFilterMethods"
        @row-click="openSaleRecord"
      >
        <template #default="{ row: sale }">
          <trac-dashboard-table-data>
            {{
              moment(sale.created_at).format("DD/MM/YYYY")
            }} </trac-dashboard-table-data
          ><trac-dashboard-table-data>
            {{ sale.receipt_number || "N/A" }}
          </trac-dashboard-table-data>
          <trac-dashboard-table-data>
            <span class="capitalize">{{ sale.sales_type || "-" }}</span>
          </trac-dashboard-table-data>
          <trac-dashboard-table-data>
            {{ sale.total_price | formatPrice }}
          </trac-dashboard-table-data>
          <trac-dashboard-table-data>
            <template v-if="sale.payment_records">
              <span v-if="sale.payment_records.length === 1" class="capitalize">
                {{
                  sale.payment_records[0].payment_method
                    ? sale.payment_records[0].payment_method
                        ?.split("_")
                        .join(" ")
                    : "N/A"
                }}
              </span>
              <span v-else class="capitalize">
                {{ sale.sale.payment_records[0].payment_method }} +
                {{ sale.sale.payment_records.slice(1).length }} other
              </span>
            </template>
          </trac-dashboard-table-data>
          <trac-dashboard-table-data>
            <template v-if="sale && sale.customer_details">
              <span class="capitalize">{{
                sale.customer_details.name || "N/A"
              }}</span>
              <!-- <span v-if="sale.payment_records.length === 1">
                {{ sale.payment_records[0].payment_reference || "N/A" }}
              </span>
              <span v-else>
                {{ sale.sale.payment_records[0].payment_reference }} +
                {{ sale.sale.payment_records.slice(1).length }} other
              </span> -->
            </template>
          </trac-dashboard-table-data>

          <trac-dashboard-table-data>
            <span class="capitalize">{{ sale.sale_agent.name || "N/A" }}</span>
          </trac-dashboard-table-data>
        </template>
      </trac-dashboard-table>

      <div class="flex justify-center mt-4">
        <trac-button
          :disabled="salesHistory.length >= totalRecords"
          @button-clicked="loadMoreProducts"
        >
          {{
            salesHistory.length >= totalRecords ? "No More Items" : "Load More"
          }}
        </trac-button>
      </div>
    </div>

    <trac-modal
      size="max-w-2xl"
      v-if="downloadCsv"
      @close="downloadCsv = false"
    >
      <div class="p-5">
        <h1 class="text-sm mt-5">Download CSV</h1>
        <div class="mt-10 w-full pr-12">
          <div class="relative w-full">
            <div
              @click="csvDropdown = !csvDropdown"
              v-click-outside="drop"
              class="border cursor-pointer h-12 rounded-md px-3 flex items-center capitalize text-xs"
            >
              <div class="flex items-center h-full" v-if="csvStore.length">
                <div v-for="(store, i) in csvStore" :key="i" class="mr-5">
                  {{ store.name }}
                </div>
              </div>
              <div class="h-full flex items-center" v-else>Select Stores</div>
              <svg
                width="16"
                height="9"
                class="absolute right-0 mr-6"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#333333"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div
              v-if="csvDropdown"
              @click.stop
              class="w-64 absolute z-50 mt-2 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                class="flex py-2 items-center cursor-pointer capitalize text-xs"
              >
                <input v-model="all" type="checkbox" class="ml-4" />
                <div class="ml-3">All</div>
              </div>
              <div
                class="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                v-for="(store, index) in getAllCsv"
                :key="index"
              >
                <div
                  class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <div
                    class="flex py-2 items-center cursor-pointer capitalize text-xs"
                  >
                    <input
                      :value="store"
                      v-model="csvStore"
                      type="checkbox"
                      class="mr-3"
                    />
                    {{ store.name }}
                  </div>
                </div>
              </div>
            </div>
            <div id="csv2" class="mt-8 w-full">
              <date-picker
                class="w-full py-3 text-xs"
                v-model="csvDate"
                format="YYYY-MM-DD"
                range
                placeholder="Select Date Range"
              ></date-picker>
            </div>
            <div class="mt-6">
              <div class="flex item-center justify-between">
                <h2 class="text-xs">Select Fields</h2>
                <div>
                  <input type="text" />
                </div>
              </div>

              <div class="mt-6 grid grid-cols-3 col-gap-4 row-gap-6">
                <div
                  v-for="(option, index) in options"
                  :key="index"
                  class="flex flex-wrap items-center"
                >
                  <input
                    type="checkbox"
                    v-model="fields"
                    :value="option"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs capitalize">
                    {{ option.replace(/_/g, " ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pb-6 flex justify-center items-center mt-16">
              <trac-button @click.native="jsonPayment">
                <span class="px-6">Download</span>
              </trac-button>
            </div>
          </div>
        </div>
      </div>
    </trac-modal>
    <trac-modal size="max-w-2xl" v-if="productCsv" @close="productCsv = false">
      <div class="p-5">
        <h1 class="text-sm mt-5">Download CSV</h1>
        <div class="mt-10 w-full pr-12">
          <div class="relative w-full">
            <div
              @click="productCsvDropdown = !productCsvDropdown"
              v-click-outside="drop"
              class="border cursor-pointer h-12 rounded-md px-3 flex items-center capitalize text-xs"
            >
              <div
                class="flex items-center h-full"
                v-if="productCsvStore.length"
              >
                <div
                  v-for="(store, i) in productCsvStore"
                  :key="i"
                  class="mr-5"
                >
                  {{ store.name }}
                </div>
              </div>
              <div class="h-full flex items-center" v-else>Select Store</div>
              <svg
                width="16"
                height="9"
                class="absolute right-0 mr-6"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#333333"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div
              v-if="productCsvDropdown"
              @click.stop
              class="w-64 absolute z-50 mt-2 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                class="flex py-2 items-center cursor-pointer capitalize text-xs"
              >
                <input v-model="allTwo" type="checkbox" class="ml-4" />
                <div class="ml-3">All</div>
              </div>
              <div
                class="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                v-for="(store, index) in getAllCsv"
                :key="index"
              >
                <div
                  class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <div
                    class="flex py-2 items-center cursor-pointer capitalize text-xs"
                  >
                    <input
                      :value="store"
                      v-model="productCsvStore"
                      type="checkbox"
                      class="mr-3"
                    />
                    {{ store.name }}
                  </div>
                </div>
              </div>
            </div>
            <div id="csv2" class="mt-8 w-full">
              <date-picker
                class="w-full py-3 text-xs"
                v-model="productCsvDate"
                format="YYYY-MM-DD"
                range
                placeholder="Select Date Range"
              ></date-picker>
            </div>
            <div class="mt-6">
              <h2 class="text-xs">Select Fields</h2>
              <div class="mt-6 grid grid-cols-3 col-gap-4 row-gap-6">
                <div
                  v-for="(option, index) in productOptions"
                  :key="index"
                  class="flex flex-wrap items-center"
                >
                  <input
                    type="checkbox"
                    v-model="productFields"
                    :value="option"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs capitalize">
                    {{ option.replace(/_/g, " ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pb-6 flex justify-center items-center mt-16">
              <trac-button @click.native="productJsonPayment">
                <span class="px-6">Download</span>
              </trac-button>
            </div>
          </div>
        </div>
      </div>
    </trac-modal>

    <!-- <SaleInfo
      v-if="viewSaleModal"
      :sale="saleInfo"
      @close="viewSaleModal = false"
    /> -->
  </main>
</template>

<script>
import { pick } from "lodash";
import { Parser } from "json2csv";
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { mapActions } from "vuex";
import { SAVE_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
// import SaleInfo from "./SaleInfo.vue";
import { eventBus } from "../../eventBus";

export default {
  directives: {
    ClickOutside,
  },

  components: { DatePicker },
  data() {
    return {
      filterFlag: "",
      download: false,
      all: false,
      viewStoreModal: false,
      employeeDropdownData: false,
      paymentDropdownData: false,
      paymentStore: false,
      paymentAll: true,
      allTwo: false,
      allEmployees: true,
      allPaymentMethods: true,
      message: "",
      searchedUserName: "",
      toggle: false,
      loading: false,
      active: false,
      open: false,
      items: [],
      time: "allTime",
      custom: "",
      moment,
      payments: [],
      filtered: null,
      original: null,
      current: `Today's`,
      search: "",
      getTableHeader: [
        "Date",
        "Customer",
        "Receipt No",
        "Transaction Type",
        "No Of Items",
        "Sales Amount",
        "Payment Methiod",
      ],
      modalObject: null,
      users: [],
      openReceipt: false,
      salesHistory: [],
      allSalesHistory: [],
      storeData: [],
      store: {},
      selectedStores: [],
      selectedEmployees: [],
      selectedPaymentMethods: [],
      paymentMethods: [
        {
          _id: "cash",
          name: "Cash",
        },
        {
          _id: "pos",
          name: "POS",
        },
        {
          _id: "online",
          name: "Online",
        },
        {
          _id: "own_bank_account",
          name: "Bank Transfer",
        },
        {
          _id: "fixed_account",
          name: "Bank transfer (Fixed)",
        },
        {
          _id: "bank_transfer",
          name: "Bank transfer (Dynamic)",
        },
      ],
      downloadCsv: false,
      csvDropdown: false,
      chosenstore: "",
      csvStore: [],
      csvDate: "",
      fields: [
        "productName",
        "sellingPrice",
        "totalCost",
        "totalSales",
        "EmployeeEmail",
        "costPrice",
        "customerName",
        "dateOfSale",
        "discount",
        "quantity",
        "storeName",
      ],
      options: [
        "EmployeeEmail",
        "costPrice",
        "customerName",
        "dateOfSale",
        "discount",
        "productName",
        "quantity",
        "sellingPrice",
        "storeName",
        "totalCost",
        "totalSales",
      ],
      productCsv: false,
      productCsvDropdown: false,
      productChosenstore: null,
      productCsvStore: [],
      productCsvDate: "",
      productOptions: [
        "dateOfSale",
        "productID",
        "productName",
        "quantitySold",
        "costPrice",
        "sellingPrice",
        "totalSales",
        "totalCost",
      ],
      productFields: [
        "dateOfSale",
        "productID",
        "productName",
        "quantitySold",
        "costPrice",
        "sellingPrice",
        "totalSales",
        "totalCost",
      ],
      params: {
        limit: 500,
        page: 1,
        updated_at: 0,
      },
      searchValue: "",
      tableHeaders: [
        {
          name: "DATE",
        },
        {
          name: "RECEIPT NO.",
        },
        {
          name: "TRANSACTION TYPE",
        },
        {
          name: "PRICE",
        },
        {
          name: "PAYMENT METHOD",
        },
        {
          name: "CUSTOMER",
        },
        {
          name: "SOLD BY",
        },
      ],
      totalRecords: 0,
      saleInfo: {},
      viewSaleModal: false,
      timeout: null,
    };
  },
  watch: {
    // allEmployees: {
    //   immediate: false,
    //   handler(x) {
    //     if (x) {
    //       this.users.forEach((employee) => {
    //         if (this.selectedEmployees.includes(employee._id)) {
    //           let index = this.selectedEmployees.indexOf(employee._id);
    //           this.selectedEmployees.splice(index, 1);
    //           this.selectedEmployees.push(employee._id);
    //         } else {
    //           this.selectedEmployees.push(employee._id);
    //         }
    //         // Added this so sales with sale_agent_id of '' will be shown
    //           // this.selectedEmployees.push('');
    //       });
    //     } else {
    //       this.selectedEmployees = [];
    //     }
    //   },
    // },
    search(val) {
      if (val) {
        this.filterFlag = "search";
        return;
      }

      this.filterFlag = "";
    },
    allPaymentMethods: {
      immediate: true,
      handler(x) {
        if (x) {
          this.paymentMethods.forEach((paymentMethod) => {
            if (!this.selectedPaymentMethods.includes(paymentMethod._id)) {
              this.selectedPaymentMethods.push(paymentMethod._id);
            } else {
              let index = this.selectedPaymentMethods.indexOf(
                paymentMethod._id
              );
              this.selectedPaymentMethods.splice(index, 1);
              this.selectedPaymentMethods.push(paymentMethod._id);
            }
          });
        } else {
          this.selectedPaymentMethods = [];
        }
      },
    },
    paymentAll: {
      immediate: true,
      handler(x) {
        if (x) {
          this.getAllCsv.forEach((store) => {
            if (!this.selectedStores.includes(store)) {
              this.selectedStores.push(store._id);
            } else {
              let index = this.selectedStores.indexOf(store);
              this.selectedStores.splice(index, 1);
              this.selectedStores.push(store._id);
            }
          });
        } else {
          this.selectedStores = [];
        }
      },
    },
    allTwo: {
      immediate: true,
      handler(x) {
        if (x) {
          this.getAllCsv.forEach((store) => {
            if (!this.productCsvStore.includes(store)) {
              this.productCsvStore.push(store);
            } else {
              let index = this.productCsvStore.indexOf(store);
              this.productCsvStore.splice(index, 1);
              this.productCsvStore.push(store);
            }
          });
        } else {
          this.productCsvStore = [];
        }
      },
    },
    all: {
      immediate: true,
      handler(x) {
        if (x) {
          this.getAllCsv.forEach((store) => {
            if (!this.csvStore.includes(store)) {
              this.csvStore.push(store);
            } else {
              let index = this.csvStore.indexOf(store);
              this.csvStore.splice(index, 1);
              this.csvStore.push(store);
            }
          });
        } else {
          this.csvStore = [];
        }
      },
    },
    custom: {
      handler(x, y) {
        this.time = "custom";
        let payload = {};
        payload.startDate = this.moment(this.custom[0]).format("YYYY-MM-DD");
        payload.endDate = this.moment(this.custom[1]).format("YYYY-MM-DD");
        this.salesHistory = [...this.allSalesHistory].filter((x) => {
          return (
            this.moment(x.created_at).format("YYYY-MM-DD") >=
              payload.startDate &&
            this.moment(x.created_at).format("YYYY-MM-DD") <= payload.endDate
          );
        });
        // this.$refs.datepicker.closePopup()
        // this.active = false
      },
    },
    time: {
      immediate: true,
      handler(x, y) {
        const vm = this;
        const payload = {};

        switch (x) {
          case "today":
            this.current = `Today's`;
            payload.startDate = this.moment().format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.salesHistory = [...this.allSalesHistory].filter((x) => {
              return (
                this.moment(x.created_at).format("YYYY-MM-DD") ===
                payload.startDate
              );
            });

            break;
          case "allTime":
            this.current = `All Time`;
            this.salesHistory = this.allSalesHistory;
            break;
          case "currentMonth":
            this.current = `This Month's`;
            payload.startDate = this.moment()
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.salesHistory = [...this.allSalesHistory].filter((x) => {
              let date = this.moment(x.created_at).format("YYYY-MM-DD");
              return date >= payload.startDate && date <= payload.endDate;
            });

            break;
          case "lastMonth":
            this.current = `Last Month's`;
            payload.startDate = this.moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            this.salesHistory = [...this.allSalesHistory].filter((x) => {
              let date = this.moment(x.created_at).format("YYYY-MM-DD");
              return date >= payload.startDate && date <= payload.endDate;
            });
            break;
          case "custom":
            this.current = "Total";
            payload.startDate = this.moment(this.custom[0]).format(
              "YYYY-MM-DD"
            );
            payload.endDate = this.moment(this.custom[1]).format("YYYY-MM-DD");
            this.salesHistory = [...this.allSalesHistory].filter((x) => {
              return (
                this.moment(x.created_at).format("YYYY-MM-DD") >=
                  payload.startDate &&
                this.moment(x.created_at).format("YYYY-MM-DD") <=
                  payload.endDate
              );
            });

            break;
          default:
            break;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchSalesHistory: "FETCH_SALES_HISTORY_V2",
    }),
    removeStoreFilter(store) {
      this.selectedStores = this.selectedStores.filter(
        (storeId) => storeId !== store._id
      );
    },
    viewSaleInfo({ row }) {
      this.saleInfo = { ...row };
      this.viewSaleModal = true;
    },
    loadMoreProducts() {
      this.params = {
        ...this.params,
        limit: this.params.limit + 10,
      };

      this.salesHistoryRequest();
    },
    updateSelectedEmployee(e) {
      if (e.target.checked) {
        this.selectedEmployees = this.users.map((user) => user._id);
      } else {
        this.selectedEmployees = [];
      }
    },
    openSaleRecord({ row: sale }) {
      SAVE_LOCAL_DB_DATA("sale-record", sale);
      this.$router.push({
        name: "SaleInfo",
        params: {
          id: sale._id || sale.offline_sale_id,
        },
      });
    },
    paymentDrop() {
      this.paymentStore = false;
    },
    employeeDropdown() {
      this.employeeDropdownData = false;
    },
    dismissPaymentDropdown() {
      this.paymentDropdownData = false;
    },
    dismissdateFilterDropdown() {
      this.active = false;
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.storeData = [...res.data] || {};
        // this.selectedStores = this.storeData.map((store) => store._id);
      }
    },
    async fetchAllUsers() {
      const res = await this.$store.dispatch("FETCH_ALL_USERS");
      if (res.status) {
        this.loading = false;
        this.users = (res.data || []).sort((a, b) =>
          (a.first_name + " " + a.last_name).trim().toLowerCase() >
          (b.first_name + " " + b.last_name).trim().toLowerCase()
            ? 1
            : -1
        );

        this.selectedEmployees = this.users.map((user) => user._id);
      }
    },
    filterPaymentsByStoreCheckbox(e) {
      // if (e.checked) {
      //   if (e.value.id.toLowerCase() === "all") {
      //     this.selectedStores = [...this.storeData.map((store) => store.id)];
      //   } else {
      //     this.selectedStores.push(e.value.id);
      //   }
      // } else {
      //   if (e.value.id.toLowerCase() === "all") {
      //     this.selectedStores = [];
      //   } else {
      //     this.selectedStores = this.selectedStores.filter(
      //       (store) => store !== e.value.id
      //     );
      //   }
      // }
    },

    drop() {
      this.csvDropdown = false;
    },
    async productJsonPayment() {
      let k = [];
      this.productCsvStore.length
        ? (k = this.productCsvStore.map(({ _id }) => {
            return _id;
          }))
        : (k = []);

      let payload = {};
      payload.startDate = this.moment(this.productCsvDate[0]).format(
        "YYYY-MM-DD"
      );
      payload.endDate = this.moment(this.productCsvDate[1]).format(
        "YYYY-MM-DD"
      );
      payload.storeIds = {
        list_of_store_ids: k,
      };
      this.download = true;
      let res = await this.$store.dispatch(
        "GET_SALES_BY_PRODUCT_JSON_PAYMENT",
        payload
      );
      if (res.data.salesByProduct) {
        this.download = false;
        let data = res.data.salesByProduct;
        let mappedData = data.map((allData) =>
          pick(allData, this.productFields)
        );
        let selected = this.productFields;
        const opts = { selected };
        try {
          const parser = new Parser(opts);
          const csv = parser.parse(mappedData);
          this.generateBlob([csv], (dataUrl) => {
            const element = document.createElement("a");
            element.href = dataUrl;
            element.download = `sales-by-products-${moment()}.csv`;
            element.click();
            this.productFields = [
              "quantitySold",
              "productName",
              "totalCost",
              "sellingPrice",
            ];
            this.productCsvDate = "";
            this.productCsv = false;
            this.productCsvStore = [];
          });
        } catch (err) {
          // console.error(err);
        }
      } else {
        this.csvActive = true;
        this.message = "There is no available data";
        this.csvType = false;
      }
    },
    async jsonPayment() {
      let k = [];
      this.csvStore.length
        ? (k = this.csvStore.map(({ _id }) => {
            return _id;
          }))
        : (k = []);

      let payload = {};
      payload.startDate = this.moment(this.csvDate[0]).format("YYYY-MM-DD");
      payload.endDate = this.moment(this.csvDate[1]).format("YYYY-MM-DD");
      payload.storeIds = {
        list_of_store_ids: k,
      };
      this.download = true;
      let res = await this.$store.dispatch(
        "GET_CUSTOMER_JSON_PAYMENT",
        payload
      );

      if (res.data.salesByCustomer) {
        this.download = false;
        let data = res.data.salesByCustomer;
        let mappedData = data.map((allData) => pick(allData, this.fields));
        let selected = this.fields;
        const opts = { selected };
        try {
          const parser = new Parser(opts);
          const csv = parser.parse(mappedData);
          this.generateBlob([csv], (dataUrl) => {
            const element = document.createElement("a");
            element.href = dataUrl;
            element.download = `sales-by-customer-${moment()}.csv`;
            element.click();
            this.fields = [
              "productName",
              "sellingPrice",
              "totalCost",
              "totalSales",
            ];
            this.csvDate = "";
            this.csvStore = [];
            this.downloadCsv = false;
          });
        } catch (err) {
          // console.error(err);
        }
      } else {
        this.csvActive = true;
        this.message = "There is no available data";
        this.csvType = false;
      }
    },
    generateBlob(csv, callback) {
      const file = new Blob(["\ufeff", csv], {
        type: "text/csv;charset=utf-8",
      });
      callback(window.URL.createObjectURL(file));
    },
    bankNameType(x) {
      if (x == "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (x == "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else if (x == "own_bank_account") {
        return "Own Bank Account";
      } else {
        return x;
      }
    },
    handleChange(x, y) {
      if (x || y) {
        this.open = false;
      }
    },

    openModal(x) {
      this.modal = true;
      this.modalObject = x;
    },
    close() {
      this.active = false;
    },
    popModal(x) {
      this.modal = true;
    },
    repeat(str, num) {
      var holder = [];
      for (var i = 0; i < num; i++) {
        holder.push(str);
      }
      return holder.join(" ");
    },

    openDate() {
      this.$refs.calender.$el.click();
    },

    titleCase(string) {
      let sentence = string.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },
    checkScroll(e) {
      const myDiv = e.target;
      const shownSalesSofar = this.salesHistory;

      if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
        if (shownSalesSofar.length === this.allSalesHistory.length) {
        } else {
          this.salesHistory.push(
            ...this.allSalesHistory.slice(
              shownSalesSofar.length,
              shownSalesSofar.length + 10
            )
          );
        }
      }
    },
    async salesHistoryRequest() {
      this.loading = true;

      const unsyncedSales =
        (await this.$GlobalOfflineManager.getAll("pos_unsynced")) || [];

      // Handles syncing of offline sales created from the POS module
      if (unsyncedSales?.length) {
        const res = await this.$store.dispatch("CREATE_OFFLINE_SALES_V2", {
          offline_sales: unsyncedSales,
        });
        if (res.status) {
          await this.$GlobalOfflineManager.clear("pos_unsynced");
          eventBus.$emit("trac-alert", {
            message: "Offline sales synced successfully",
          });
        }
      }

      const res = await this.fetchSalesHistory({
        ...this.params,
        stores: this.selectedStores,
      });
      this.loading = false;
      this.salesHistory = res.sales ?? [];
      this.allSalesHistory = res.sales ?? [];
      // this.totalRecords = res.total_count;

      // this.loading = true;
      // let payload = {
      //   _id: this.store.id,
      // };
      // // payload._id = this.store.id;
      // // let res = await this.$store.dispatch("FETCH_SALES_HISTORY", payload);
      // let offlineSales = (await this.$store.dispatch(
      //   "FETCH_OFFLINE_SALES",
      //   payload
      // )) || { data: { salesHistory: [] } };
      // payload.lastUpdate =
      //   ((offlineSales.data || {}).metaData || {}).lastUpdate || 0;
      // let res = await this.$store.dispatch("FETCH_SALES_HISTORY_V2", payload);

      // this.unsynced_sales =
      //   (await this.$GlobalOfflineManager.getAll("pos_unsynced")) || [];
      // // debugger

      // if (res.status && res.data) {
      //   const combined = [].concat(
      //     res.data.salesHistory || [],
      //     offlineSales.data.salesHistory
      //   );
      //   this.salesHistory = combined.slice(0, 30);
      //   this.allSalesHistory = combined || [];

      //   this.time = "allTime";
      //   // this.salesHistory = [...this.allSalesHistory].filter((x) => {
      //   //   return (
      //   //     this.moment(x.created_at || x.offline_date).format("YYYY-MM-DD") ===
      //   //     this.moment().format("YYYY-MM-DD")
      //   //   );
      //   // });

      //   this.loading = false;
      // } else {
      //   this.salesHistory = offlineSales.data.salesHistory;
      //   this.allSalesHistory = offlineSales.data.salesHistory;
      //   this.loading = false;
      // }
    },
  },
  computed: {
    filteredUsers() {
      return this.users.filter(
        (user) =>
          (user.first_name || "")
            .toLowerCase()
            .includes(this.searchedUserName.toLowerCase()) ||
          (user.last_name || "")
            .toLowerCase()
            .includes(this.searchedUserName.toLowerCase())
      );
    },
    selectedStoresDetails() {
      return this.storeData.filter((store) =>
        this.selectedStores.includes(store._id)
      );
    },
    checkedFilterItems() {
      return this.selectedStores;
    },
    allHistory() {
      return this.salesHistory.filter((x) => {
        if (x.customer_name) {
          return (x.customer_name || "")
            .toLowerCase()
            .includes(this.search.toLowerCase());
        } else {
          return (x.receipt_number || "")
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
      });
    },
    sumOfCurrentTime() {
      return (
        this.time === "allTime"
          ? this.allSalesHistory
          : this.sortbyOtherFilterMethods
      ).reduce((total, sale) => total + (sale.total_price || 0), 0);
    },
    sortbyOtherFilterMethods() {
      let allData = this.salesHistory || [];
      let allFilteredData = [];
      const vm = this;

      switch (this.filterFlag) {
        case "store":
          allFilteredData = allData.filter((sale) =>
            this.selectedStores.includes(sale.store?.id)
          );
          break;
        case "employees":
          allFilteredData = allData.filter((sale) =>
            this.allEmployees
              ? true
              : this.selectedEmployees.includes(sale.sale_agent?.id)
          );
          break;
        case "search":
          allFilteredData = allData.filter(
            (sale) =>
              sale.sale_agent?.name
                ?.toLowerCase()
                ?.match(vm.search.toLowerCase()) ||
              sale.receipt_number?.match(vm.search)
          );
          break;
        case "payment":
          allFilteredData = allData.filter((sale) =>
            (sale?.payment_records ?? []).some((record) =>
              vm.selectedPaymentMethods.includes(record.payment_method)
            )
          );
          break;
        default:
          allFilteredData = allData;
          break;
      }
      return [...allFilteredData].sort((a, b) =>
        moment(a.created_at).isBefore(b.created_at) ? 1 : -1
      );
    },
    getTotal() {
      return this.sortbyOtherFilterMethods.reduce(
        (a, b) => a + b.total_price,
        0
      );
    },
    // initials() {
    //   let k = [...this.payments.list_of_payments];

    //   let b = k.map((u) => {
    //     return this.getInitials(u.name);
    //   });
    //   return b;
    // },
    getAllCsv() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });

      return u;
    },
    getAllProductCsv() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });

      return u;
    },
    getStore() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });
      let v = [...u];
      return v;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchAllStores();
    await this.fetchAllUsers();
    await this.salesHistoryRequest();
    this.loading = false;
  },
};
</script>

<style>
.dd {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.hov:hover {
  background: rgba(190, 182, 182, 0.173);
}

.max-area {
  max-height: 70vh;
  overflow-y: auto;
}

.width {
  width: 22rem;
}
</style>
<style scoped>
.white-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}

.max-heights {
  max-height: 15rem;
  overflow-y: auto;
}
</style>
<style>
.dd {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.hov:hover {
  background: rgba(190, 182, 182, 0.173);
}

#csv2 .mx-input {
  height: 44px !important;
  font-size: 12px;
}

#csv2 .mx-input::placeholder {
  color: #333 !important;
}
</style>
